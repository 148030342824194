// fonts
@font-face {
  font-family: 'Matter';
  font-display: fallback;
  src: url('../fonts/matter/Matter-Regular.woff2') format('woff2'),
       url('../fonts/matter/Matter-Regular.eot') format('eot'),
       url('../fonts/matter/Matter-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Tobias';
  font-display: fallback;
  src: url('../fonts/tobias/Tobias-Regular.woff2') format('woff2'),
       url('../fonts/tobias/Tobias-Regular.eot') format('eot'),
       url('../fonts/tobias/Tobias-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
$ff--sans: 'Matter', sans-serif;
$ff--serif: 'Tobias', serif;
