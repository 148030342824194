//
.box-group__online-status {
  top: $s11;
  height: 1px;

  .wrap {
    height: 1px;
  }

  &.is-visible {
    .toaster__online-status {     
      transform: translateY(38px);
      transition: all 0.5s cubic-bezier(0, 0.14, 0.25, 0.9);
    }
  }

  .toaster__online-status {       
    display: flex;
    width: calc(380px - $s10);
    z-index: 20;
    transition: all 0.5s cubic-bezier(0, 0.14, 0.25, 0.9);
    transform: translateY(-210px);
    margin-right: 2px;

    &::after {
      content: '' ;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;    
      backdrop-filter: blur(8px);
      border: 1px solid var(--background-300);
      @include background-opacity($c--light-300,0.5);
      @include border-radius($s2);
    }  

    .online__avatar {
      z-index: 20;
      display: none;
      figure {     
        border: 1px solid var(--text-300);   
        height: $s9;
        width: $s9;
        @include border-radius(50%);
      }     
      canvas {
        width: 80px;
      } 
    }
   
    .online__content {
      z-index: 20;
      gap: $s05;
      margin-left: $s1;
      button {
        background-color: var(--background-300);
        border:none;
        display: block;
        top: $s2;
        right: $s2;
        width: $s3;
        height: $s3;
        overflow: hidden;
        padding: 0;
        text-indent: -5000px;
        
        @include border-radius($s1);

        &::after {
          content: '' ;
          background: transparent url('/resources/images/icon__times.svg') center center no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include multi-filter(brightness(0.5));
        }

        &:hover {
          @include opacity(0.5)
        }
      }
      .online__extra{   
        * {
          color: var(--text-100);
          @include opacity(0.8)
        } 
        a {
          &:hover {
            color: var(--link-color);
          }
        }    
        .online__status {
          
          &::before {
            background-color: $c--warning;
            content: '';          
            position: absolute;
            left: 0;
            top: $s1;
            width: $s1;
            height: $s1;
            @include border-radius(50%);
          }
          &.online {
            &::before {
              background-color: $c--success;
            }  
          }
        }
      }      
    }  
  }
  @include for-desktop-hd {
    .toaster__online-status {  
      width: 377px;
      .online__avatar {display: block;}
      .online__content {margin-left: $s2;}
    }
  }
}

.dark-mode {
  .box-group__online-status {  
    .toaster__online-status {   
      &::after {
        @include background-opacity($c--dark-100,0.5);
      }   
      .online__avatar { 
        canvas {
          @include multi-filter(brightness(10));
        } 
      } 
    }
  }
}

