//
@use 'sass:string';
@use 'sass:math';

// Fluid Type stolen from
// https://css-tricks.com/snippets/css/fluid-typography/
@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-font-size, $max-font-size) {
  $u1: math.unit($bp-tablet);
  $u2: math.unit($bp-desktop-fhd);
  $u3: math.unit($min-font-size);
  $u4: math.unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $bp-tablet) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            (
              (100vw - #{$bp-tablet}) / #{strip-unit(
                  $bp-desktop-fhd - $bp-tablet
                )}
            )
        );
      }

      @media screen and (min-width: $bp-desktop-fhd) {
        font-size: $max-font-size;
      }
    }
  }
}

// 400
@mixin for-half-mobile {
  @media (min-width: 400px) {
    @content;
  }
}

// 568px
@mixin for-mobile {
  @media (min-width: $bp-mobile) {
    @content;
  }
}

// 768px
@mixin for-tablet {
  @media (min-width: $bp-tablet) {
    @content;
  }
}

// 1024px
@mixin for-desktop {
  @media (min-width: $bp-desktop) {
    @content;
  }
}

// 1280px
@mixin for-desktop-hd {
  @media (min-width: $bp-desktop-hd) {
    @content;
  }
}

// 1600px
@mixin for-desktop-uxga {
  // middle between HD and FULL HD
  @media (min-width: $bp-desktop-uxga) {
    @content;
  }
}

// 1920px
@mixin for-desktop-fhd {
  @media (min-width: $bp-desktop-fhd) {
    @content;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); // IE8
}

@mixin single-filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type + unquote('(#{$filter-amount})');
  -moz-filter: $filter-type + unquote('(#{$filter-amount})');
  -ms-filter: $filter-type + unquote('(#{$filter-amount})');
  -o-filter: $filter-type + unquote('(#{$filter-amount})');
  filter: $filter-type + unquote('(#{$filter-amount})');
}

@mixin multi-filter($filter) {
  -webkit-filter: $filter;
  -moz-filter: $filter;
  -ms-filter: $filter;
  -o-filter: $filter;
  filter: $filter;
}

@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background-color: $color;
  background-color: rgba($color, $opacity);
}

@mixin backdrop($val) {
  -webkit-backdrop-filter: blur($val);
  -moz-backdrop-filter: blur($val);
  backdrop-filter: blur($val);
}

@mixin transition($prop, $secs, $timing) {
  -webkit-transition: $prop $secs $timing;
  -moz-transition: $prop $secs $timing;
  -ms-transition: $prop $secs $timing;
  -o-transition: $prop $secs $timing;
  transition: $prop $secs $timing;
}

@mixin multi-transition($prop1, $prop2) {
  -webkit-transition: $prop1, $prop2;
  -moz-transition: $prop1, $prop2;
  -ms-transition: $prop1, $prop2;
  -o-transition: $prop1, $prop2;
  transition: $prop1, $prop2;
}

@mixin flex-box($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin transforms($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
