//
.button__cta {
  //align-self: start;
  background: transparent;
  color: var(--text-300);
  border: 5px solid var(--background-300);
  display: flex;
  font-size: $fs--xxx-normal;
  position: relative;
  width: max-content;

  @include border-radius($s4);
  @include transition(all, 0.2s, ease-in-out);

  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 14px;
    top: 20px;
    right: 24px;
    display: inline-block;
    background: transparent url('/resources/images/icon__arrow--button.svg') center center no-repeat;
    @include opacity(0);
    @include multi-filter(brightness(0));
    @include transition(all, 0.2s, ease-in-out);
  }

  &:hover {
    border: 5px solid var(--link-color);
    padding-right: $s6;
    color: var(--text-100);

    &::after {
      right: 12px;
      @include opacity(1);
      @include transition(all, 0.2s, ease-in-out);
    }
  }

  span {
    font-size: $fs--xx-small;
    margin: 0 0 0 $s1;
  }

}

.dark-mode {
 .button__cta {          
  &::after {
    @include multi-filter(brightness(1));
  }
}
}