// reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
b,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

p,
li,
a,
button,
input,
textarea,
option,
label,
span,
small {
  font-family: $ff--sans;
  outline: none;
}

h1, 
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $ff--serif;
}

ul,
ol {
  list-style-type: none;
  list-style-position: inside;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

*,
*::before,
*::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

body {
  background-color: var(--background-100);

  ::-moz-selection {
    color: var(--text-300);
    background: var(--link-color);
  }

  ::selection {
    color: var(--text-300);
    background: var(--link-color);
  }
}
body.dark-mode {
  ::-moz-selection {
    color: var(--link-color);
    background: var(--background-200);
  }

  ::selection {
    color: var(--link-color);
    background: var(--background-200);
  }
}

main {
  background-color: var(--background-100);
  position: relative;
  min-height: 100vh;
  z-index: 2;
  padding: 130px 0 $s5 0;

  > .wrap {
    background-color: var(--background-color);
  }

}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--text-300);

  &:hover {
    @include transition(all, 0.3s, ease-in-out);
    color: var(--link-color);
  }
}
