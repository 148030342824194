//
header {
  mix-blend-mode: difference;
  height: $s11;
  
  .logo {
    background: transparent url('/resources/images/logo__anywhere--white.svg') center/100% no-repeat;
    display: inline-block;
    height: 35px;
    overflow: hidden;
    text-indent: -5000px;
    width: 144px;
  }
}

.dark-mode {
  header {
    .logo {
      background: transparent url('/resources/images/logo__anywhere--white.svg') center/100% no-repeat;
    }
  }
}
