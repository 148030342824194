/* -- schema
nav 

*/

nav {
  top: $s11;
  height: 1px;  

  .nav-content {
    gap:$s1;
    top: -$s9;
    right: $s2;
  }
    
  a {
    border: 1px solid var(--background-300);
    color: var(--text-100);    
    display: inline-block;
    font-family: $ff--sans;
    font-size: $s--xxx-small;
    margin: $s05 0;
    padding: $s1-5 $s2-5;

    @include border-radius($s2);

    &:hover {
      @include opacity(0.65);
    }
  }
  .link--icon {
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    text-indent: -5000px;
    backdrop-filter: blur(8px);
    @include background-opacity($c--light-300,0.5);
  }
  .link--contrast {
    background-image: url('/resources/images/icon__contrast.svg');
  }
  .link--info {
    background-image: url('/resources/images/icon__information.png');
  }
  .link--menu {
    background-image: url('/resources/images/icon__menu.png');
  }
  .menu {
    align-items: flex-start;
    backdrop-filter: blur(8px);
    border: 1px solid var(--background-300);
    flex-direction: column;
    position: absolute;
    top: 54px;
    width: 144px;
    display: none;
    gap: 0;

    @include background-opacity($c--light-300,0.5);
    @include transforms(translateX(0));
    @include border-radius($s2);

    a{
      width: calc(100% - 48px);
      border: none;
      background-color: transparent;
      margin:0;
    }

    &.is-visible {
      animation: moveInFromTop 0.5s cubic-bezier(0, 0.14, 0.25, 0.9);;
      display: block;
      overflow: hidden;
    }

    @keyframes moveInFromTop {
      from {
        max-height: 1px;
        @include opacity(0);
        @include transforms(translate(0, -10px));
      }
      to {
        max-height: 550px;
        @include opacity(1);
        @include transforms(translate(0, 0));
      }
    }
    
  }
  @include for-tablet {
    .nav-content {
      right: $s3;
    }
  }
  @include for-desktop-hd {
    .nav-content {
      right: $s5;
    }
    .link--menu {
      display: none;
    }
    .menu {
      align-items: flex-start;
      background-color: transparent;
      backdrop-filter: unset;
      border: 0px solid transparent;
      display: flex;      
      flex-direction: row;
      position: relative;
      top: unset;
      width: auto;
      gap:$s1;
      @include border-radius(0);
      
      a{
        width: auto;
        margin: $s05 0;
        border: 1px solid var(--background-300);
        backdrop-filter: blur(8px);

        @include background-opacity($c--light-300,0.5);
      }     
    }
  }
}
.dark-mode {
  nav {
    .link--contrast {
      background-image: url('/resources/images/icon__contrast--white.svg');
    }
    .link--icon {
      @include background-opacity($c--dark-100,0.5);   
    }
    .menu {
      @include background-opacity($c--dark-100,0.5);     
    }
    
    @include for-desktop-hd {   
      .menu {
        background-color: transparent;
        a {
          @include background-opacity($c--dark-100,0.5);
        }
      }    
    }
  }
 
}