
// -- card work
.card__work {  
  .card__thumb {
    aspect-ratio: 3/2;
    width: 100%;
    //height: 205px;
    margin-bottom: $s2; 
    overflow: hidden;  
      @include border-radius($s2);  
    
    picture {
      position: absolute;
      overflow: hidden;  
      top: 0;      
      z-index: 2;
      width: 100%;
      height: 100%;   

      @include border-radius($s2);
      @include transition(all, 0.50s, ease-in-out);

      img {           
        width: 100%;
        height: 100%;   
        object-fit: cover;
        position: absolute;
        @include transition(all, 0.50s, ease-in-out);
      }
    }
    video{
      height: 100%;
    }
    picture + picture, picture + video{
      z-index: 1;
    }
    @include for-half-mobile {
      //height: 305px;
    }
    @include for-mobile {
      //height: 205px;
    }
    @include for-tablet {
      //height: 250px;
    }
    @include for-desktop {
      //height: 325px;
    }
    @include for-desktop-hd {
      //height: 430px;
    }    
  }

  a{
    &:hover {
      .card__thumb {
        picture:first-child {
          @include opacity(0);
        }
        picture + picture{
          img {
            @include transforms(scale(1.05));
          }
        }    
      }
    }
  }
  .card__content {
    .card__text{
      width:100%;
      gap: $s1-5;
      .card__title{        
        color: var(--text-200);
        font-size: $fs--xx-normal;
        line-height: $lh--xxx-normal;
        margin-bottom: $s1;
      }
      .card__description{
        color: var(--text-100);
        font-size: $fs--xxx-small;
        line-height: $lh--x-normal;
      }
    }    
    .card__tags{
      align-items: center;
      flex-wrap: wrap;
      gap: $s1-5 $s1;
      width: 100%;
      margin-bottom: $s2; 
      align-items: flex-start;
      margin-top: $s1;
      li {
        color: var(--text-100);
        font-size: $fs--xx-small;
        line-height: $lh--xx-small;
        text-align: left;
        opacity: 70%;
        text-align: end;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
    }
  }
}
  
  
  // -- card service
  .card__service {  
    overflow: hidden;  
    &.card__online-and-digital {background-color: $c--orange;}
    &.card__brand-identity {background-color: $c--gold;}
    &.card__creative-content {background-color: $c--info;}
    &.card__print-and-environmental {background-color: $c--purple;}
    
    .card__thumb {
      margin-bottom: $s3;      
      
      picture {
        width: 100%;
        height: 216px;
        display: block;
        overflow: hidden;
  
        @include border-radius($s2);
  
        img{
          width: 100%;
          height: 100%;
        }      
      }    
    }
    .card__title{
      color: $c--dark-300;
      margin-bottom: $s2;
  
      &:hover{
        @include opacity(0);
      }
    }
    .card__content {
      margin-bottom: $s1;
      p{      
        color: $c--dark-300;
      }
      ul{
        margin-top: $s2;
        li {
          color: $c--dark-300;
          @include fluid-type($fs--normal, $fs--normal);  // 16px

          @include for-desktop {
            line-height: $lh--xxx-normal;
          }
        }
      }  
    }
    @include for-tablet {
      margin-bottom: $s1;
      height: 100%;
      @include border-radius($s2);
      
      .card__thumb {     
        order: 1;
  
        picture {
          height: 225px;
          @include border-radius($s2 $s2 0 0);
        }
      }
      .card__title { 
        order: 2;
        padding-left: $s3;
        margin-bottom: 0;
      }
      .card__content {
        padding: $s2 $s3 $s1 $s3;
        order: 3;
      }
    }
    @include for-desktop-hd {
      .card__thumb {
        picture {
          height: 210px;
        }
      }
    }
  }