h1,
.h1 {
  color: var(--text-300);
  font-weight: $fw--light;
  line-height: $lh--xx-big;
  margin: 0 0 $s3 0;
  @include fluid-type($fs--big, $fs--hudge); // 58px/66px - 32px/36px

  @include for-desktop {
    line-height: $lh--ultra;
    margin: 0 0 $s4 0;
  }
}

h2,
.h2 {
  color: var(--text-300);
  font-weight: $fw--light;
  line-height: $lh--big;
  margin: $s3 0;
  @include fluid-type($fs--xx-medium, $fs--xxx-big); // 38px/44px - 28px/32px

  @include for-desktop {
    line-height: $lh--xx-large;    
  }
}

h3,
.h3 {
  color: var(--text-300);
  font-weight: $fw--light;
  font-family: $ff--sans;
  line-height: $lh--xx-medium;
  margin: $s3 0;
  @include fluid-type($fs--xxx-normal, $fs--xxx-normal); // 22px/28px - 22px/28px

  @include for-desktop {
    line-height: $lh--xx-medium;
  }
}

h4,
.h4 {
  color: var(--text-300);
  font-weight: $fw--light;
  font-family: $ff--sans;
  line-height: $lh--medium;
  margin: $s2 0;
  @include fluid-type($fs--xx-normal, $fs--xx-normal); // 20px/22px 

  @include for-desktop {
    line-height: $lh--medium;
  }
}


span,
.span {
  color: var(--text-300);
}
p,
.p {
  color: var(--text-300);
  font-weight: $fw--light;
  font-family: $ff--sans;
  line-height: $lh--xx-normal;
  @include fluid-type($fs--normal, $fs--normal);  // 16px

  @include for-desktop {
    line-height: $lh--xxx-normal;
  }

  a {
    color:var(--link-color);
    display: inline-block;
    position: relative;

    &::before {
      background-color:var(--link-color);
      bottom: 0px;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;

      @include transforms(scaleX(0));
      @include transition(all, 0.3s, ease);
      @include opacity(0);
    }

    &:hover::before {
      @include transforms(scaleX(1));
      @include opacity(1);
    }

    &:hover {
      color: var(--link-color);
      text-decoration: none;
    }
  }
}

em {
  font-style: italic;
}

small,
.small {
  color: var(--text-300);
  font-weight: $fw--light;
  line-height: $lh--xx-medium;
  @include fluid-type($fs--xx-small, $fs--xx-small);  // 12px

  @include for-desktop {
    line-height: $lh--medium;
  }
}
