


// common colors
$c--alert: #f4c93b;
$c--warning: #c1272d;
$c--success: #31b102;
$c--info: #81B9DB;
$c--link: #FEA900;
$c--grey: #4A4A4A;

// custom colors
$c--gold: #C4B182;
$c--purple: #DEBDFF;
$c--orange: #FF7843;

$c--dark-300: #070707; //$c--dark
$c--dark-200: #121212; //$c--off-dark
$c--dark-100: #2D2D2D; //$c--dark-grey

$c--light-300: #BEBEBE; //$c--light-grey
$c--light-200: #E8E7E1; //$c--off-light
$c--light-100: #F5F3ED; //$c--light

// light mode
:root {
  --background-300: #{$c--light-300};
  --background-200: #{$c--light-200};
  --background-100: #{$c--light-100};
  --text-300: #{$c--dark-300};
  --text-200: #{$c--dark-200};
  --text-100: #{$c--dark-100};
  --link-color: #{$c--link};
}
// darkmode mode
body.dark-mode {
  --background-300: #{$c--dark-100};
  --background-200: #{$c--dark-200};
  --background-100: #{$c--dark-300};
  --text-300: #{$c--light-100};
  --text-200: #{$c--light-200};
  --text-100: #{$c--light-300};
  --link-color: #{$c--link};
}

$group-colors: (
  'link': $c--link,
  'gold': $c--gold,
  'info': $c--info,
  'purple': $c--purple,
  'orange': $c--orange,
  'light-100': $c--light-100,
  'light-200': $c--light-200,
  'light-300': $c--light-300,
  'grey': $c--grey,
  'dark-100': $c--dark-100,
  'dark-200': $c--dark-200,
  'dark-300': $c--dark-300
);

// default unit for spaces
$du--space: 8px;

// spaces
$s05: 0.5 * $du--space; // 4px (0.25rem base 16px)
$s1: 1 * $du--space; // 8px (0.5rem base 16px)
$s1-5: 1.5 * $du--space; // 12px (0.75rem base 16px)
$s2: 2 * $du--space; // 16px (1rem base 16px)
$s2-5: 2.5 * $du--space; // 20px (1rem base 16px)
$s3: 3 * $du--space; // 24px
$s4: 4 * $du--space; // 32px (2rem base 16px)
$s5: 5 * $du--space; // 40px
$s6: 6 * $du--space; // 48px (3rem base 16px)
$s7: 7 * $du--space; // 56px (3.5rem base 16px)
$s8: 8 * $du--space; // 64px (4rem base 16px)
$s9: 9 * $du--space; // 72px (4rem base 16px)
$s10: 10 * $du--space; // 80px (5rem base 16px)
$s11: 11 * $du--space; // 88px
$s12: 12 * $du--space; // 96px
$s13: 13 * $du--space; // 104px
$s14: 14 * $du--space; // 112px
$s15: 15 * $du--space; // 120px

$group-sizes: (
  '0': 0,
  '05': $s05,
  '1': $s1,
  '1-5': $s1-5,
  '2': $s2,
  '2-5': $s2-5,
  '3': $s3,
  '4': $s4,
  '5': $s5,
  '6': $s6,
  '7': $s7,
  '8': $s8,
  '9': $s9,
  '10': $s10,
  '11': $s11,
  '12': $s12,
  '13': $s13,
  '14': $s14,
  '15': $s15
);

/*
16px -> normal 1x
18 - x   1.125x
20 - xx  1.25x
22 - xxx 1.375x
24px -> medium 1.5
26 - x   1.625x
28 - xx  1.75x
30 - xxx 1.87x
32px -> large

tiny > small > normal > medium > big > large > super > hudge > ultra
*/

// default unit for fonts
$du--fonts: 16px;

$s--small: $du--fonts * 0.5; // 0.5x  small =8px
$s--xx-small: $du--fonts * 0.75; // =12px
$s--xxx-small: $du--fonts * 0.875; // =14px
$s--normal: $du--fonts * 1; // 1x    normal =16px
$s--x-normal: $du--fonts * 1.125; // =18px
$s--xx-normal: $du--fonts * 1.25; // =20px
$s--xxx-normal: $du--fonts * 1.375; // =22px
$s--medium: $du--fonts * 1.5; // 1.5x  medium =24px
$s--xx-medium: $du--fonts * 1.75; // =28px
$s--big: $du--fonts * 2; // 2x    big  =32px
$s--xx-big: $du--fonts * 2.25; // =36px
$s--xxx-big: $du--fonts * 2.375; // =38px
$s--large: $du--fonts * 2.5; // 2.5x  large =40px
$s--xx-large: $du--fonts * 2.75; // =44px
$s--xxx-large: $du--fonts * 2.875; // =46px
$s--super: $du--fonts * 3; // 3x    super =48px
$s--hudge: $du--fonts * 3.5; // 3.5x  hudge =56px
$s--ultra: $du--fonts * 4; // 4x    ultra =64px
$s--colosal: $du--fonts * 6; // 6x    colosal =96px

$group-fonts: (
  'small': $s--small,
  'xx-small': $s--xx-small,
  'xxx-small': $s--xxx-small,
  'normal': $s--normal,
  'x-normal': $s--x-normal,
  'xx-normal': $s--xx-normal,
  'xxx-normal': $s--xxx-normal,
  'medium': $s--medium,
  'xx-medium': $s--xx-medium,
  'big': $s--big,
  'xx-big': $s--xx-big,
  'xxx-big': $s--xxx-big,
  'large': $s--large,
  'xx-large': $s--xx-large,
  'xxx-large': $s--xxx-large,
  'super': $s--super,
  'hudge': $s--hudge,
  'ultra': $s--ultra,
  'colosal': $s--colosal,
);

// line height alias
$lh--small: $s--small;
$lh--xx-small: $s--xx-small;
$lh--xxx-small: $s--xxx-small;
$lh--normal: $s--normal;
$lh--x-normal: $s--x-normal;
$lh--xx-normal: $s--xx-normal;
$lh--xxx-normal: $s--xxx-normal;
$lh--medium: $s--medium;
$lh--xx-medium: $s--xx-medium;
$lh--big: $s--big;
$lh--xx-big: $s--xx-big;
$lh--xxx-big: $s--xxx-big;
$lh--large: $s--large;
$lh--xx-large: $s--xx-large;
$lh--xxx-large: $s--xxx-large;
$lh--super: $s--super;
$lh--hudge: $s--hudge;
$lh--ultra: $s--ultra;
$lh--colosal: $s--colosal;

// font sizes alias
$fs--small: $s--small;
$fs--xx-small: $s--xx-small;
$fs--xxx-small: $s--xxx-small;
$fs--normal: $s--normal;
$fs--x-normal: $s--x-normal;
$fs--xx-normal: $s--xx-normal;
$fs--xxx-normal: $s--xxx-normal;
$fs--medium: $s--medium;
$fs--xx-medium: $s--xx-medium;
$fs--big: $s--big;
$fs--xx-big: $s--xx-big;
$fs--xxx-big: $s--xxx-big;
$fs--large: $s--large;
$fs--xx-large: $s--xx-large;
$fs--xxx-large: $s--xxx-large;
$fs--super: $s--super;
$fs--hudge: $s--hudge;
$fs--ultra: $s--ultra;
$fs--colosal: $s--colosal;

$fw--extra-light: 200;
$fw--light: 300;
$fw--regular: 400;
$fw--semi-bold: 600;
$fw--bold: 700;
$fw--extra-bold: 800;

// Breakpoints:
$group-breakpoints: (
  'mobile': 'sn',
  'tablet': 'md',
  'desktop': 'lg',
  'desktop-hd': 'hd',
  'desktop-uxga': 'uxga',
  'desktop-fhd': 'fhd'
);
$bp-mobile: 568px; //sm
$bp-tablet: 768px; //md
$bp-desktop: 1024px; //lg
$bp-desktop-hd: 1280px; //hd
$bp-desktop-uxga: 1600px; //uxga
$bp-desktop-fhd: 1920px; //fhd

// animation
$a--cubic-bezier: cubic-bezier(0.22, 0.61, 0.36, 1);
