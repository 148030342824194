// grid
.pure-center {
  justify-content: center;
}

.container {
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
}

.wrap {
  box-sizing: border-box;
  max-width: 1636px;
  margin-left: auto;
  margin-right: auto;
}

.wrap-padding {
  padding-left: $s2;
  padding-right: $s2;
  max-width: calc(1636px - $s4);

  @include for-tablet {
    padding-left: $s3;
    padding-right: $s3;
    max-width: 1636px;
    //max-width: calc(1636px - $s6);
  }
  @include for-desktop-hd {
    padding-left: $s5;
    padding-right: $s5;
    //max-width: calc(1636px - $s10);
  }
}

.ff--sans {
  font-family: $ff--sans;
}
.ff--serif {
  font-family: $ff--serif;
}

.w--100 {
  width: 100%;
}

.w--75 {
  width: 75%;
}

.w--50 {
  width: 50%;
}

.w--33 {
  width: 33%;
}

.w--25 {
  width: 25%;
}

.o--hidden {
  overflow: hidden;
}

.v--hidden {
  visibility: hidden !important;
}
.v--visible {
  visibility: visible !important;
}

.d--none {
  display: none !important;
}

.d--block {
  display: block !important;
}

.d--inline-block {
  display: inline-block;
}

.d--flex {
  display: flex;
}

.d--inline-flex {
  display: inline-flex;
}

.ta--center {
  text-align: center;
}

.ta--left {
  text-align: start;
}

.ta--right {
  text-align: end;
}

.tt--uppercase {
  text-transform: uppercase;
}

.ai--center {
  align-items: center;
}

.ai--flex-start {
  align-items: flex-start;
}

.jc--center {
  justify-content: center;
}

.jc--space-between {
  justify-content: space-between;
}

.jc--space-around {
  justify-content: space-around;
}

.jc--flex-end {
  justify-content: flex-end;
}

.fw--wrap {
  flex-wrap: wrap;
}

.fd--column {
  flex-direction: column;
}

.p--relative {
  position: relative;
}

.p--fixed {
  position: fixed;
}

.p--sticky {
  position: sticky;
}

.p--absolute {
  position: absolute;
}

.t--0 {
  top: 0;
}
.b--0 {
  bottom: 0;
}
.l--0 {
  left: 0;
}
.r--0 {
  right: 0;
}

.b--none {
  border: none;
}

.b--test {
  border: 1px dashed var(--link-color);
}

.ar--1-1 {
  aspect-ratio: 1/1;
}

.ar--16-9 {
  aspect-ratio: 16/9;
}

.ar--4-3 {
  aspect-ratio: 4/3;
}

@each $name, $val in $group-sizes {
  @if $val < 25 {
    .br--#{$name} {
      border-radius: $val;
    }
  }
}

@each $name, $val in $group-sizes {
  @if $val < 50 {
    .g--#{$name} {
      gap: $val;
    }
  }
}

.bc--transparent {
  background-color: transparent;
}

@each $name, $val in $group-colors {
  .bc--#{$name} {
    background-color: $val;
  }
}

@each $name, $val in $group-colors {
  .c--#{$name} {
    color: $val;
  }
}

@each $name, $val in $group-fonts {
  .fs--#{$name} {
    font-size: $val;
  }
  .lh--#{$name} {
    line-height: $val;
  }
}

$zindex: 10;
@for $i from 0 through $zindex {
  .zi--#{$i * 10} {
    z-index: #{$i * 10};
  }
}

.mx--auto {
  margin-left: auto;
  margin-right: auto;
}
.ml--auto {
  margin-left: auto;
}
.mr--auto {
  margin-right: auto;
}
@each $name, $val in $group-sizes {
  .mt--#{$name} {
    margin-top: $val;
  }
  .mb--#{$name} {
    margin-bottom: $val;
  }
  .ml--#{$name} {
    margin-left: $val;
  }
  .mr--#{$name} {
    margin-right: $val;
  }
  .m--#{$name} {
    margin: $val;
  }
  .my--#{$name} {
    margin-top: $val;
    margin-bottom: $val;
  }
  .mx--#{$name} {
    margin-left: $val;
    margin-right: $val;
  }

  .pt--#{$name} {
    padding-top: $val;
  }
  .pb--#{$name} {
    padding-bottom: $val;
  }
  .pl--#{$name} {
    padding-left: $val;
  }
  .pr--#{$name} {
    padding-right: $val;
  }
  .p--#{$name} {
    padding: $val;
  }
  .py--#{$name} {
    padding-top: $val;
    padding-bottom: $val;
  }
  .px--#{$name} {
    padding-left: $val;
    padding-right: $val;
  }
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate3d(.63, 1, 0, 0deg)
  }

  to {
    transform: rotate3d(.63, 1, 0, 1turn)
  }
}

@keyframes rotation {
  0% {
    transform: rotate3d(.63, 1, 0, 0deg)
  }

  to {
    transform: rotate3d(.63, 1, 0, 1turn)
  }
}

.box__animation {
  aspect-ratio: 1/1;
  height: auto;  
  position: relative;
  overflow: hidden;  
}

.animation-container {
  height: 100%;
  width: 100%
}

.animation-container .circle {
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-duration: 7.5s;
  animation-duration: 7.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotation;
  animation-name: rotation;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  border: 1px solid var(--text-300);
  border-radius: 50%;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  transform-origin: center;
  width: 100%
}

.animation-container .circle:first-of-type {
  -webkit-animation-delay: 5s;
  animation-delay: 5s
}

.animation-container .circle:last-of-type {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s
}

canvas {
  width: 100%;
  height: 100%;
}

@include for-tablet {
  .page__title {
    width: 50%;
  }
}