//
.link {
  font-size: $fs--normal;
  font-weight: $fw--regular;
  line-height: $lh--medium;
}
.link--email {
  background: transparent url('/resources/images/icon__copy.svg') right center no-repeat;   
  display: inline-block;
  font-size: $fs--x-normal;  
  padding-right: $s3;
  position: relative;
  width: fit-content;

  &::before {
    background-color:var(--link-color);
    bottom: 0px;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;

    @include transforms(scaleX(0));
    @include transition(all, 0.3s, ease);
    @include opacity(0);
  }

  &:hover::before {
    @include transforms(scaleX(1));
    @include opacity(1);
  }

  &:hover {
    color: var(--text-100);
    text-decoration: none;
  }
  
}

.tooltip {
  display: none;
  position: absolute;
  background-color:var(--text-100); 
  color: var(--background-200);
  padding: $s1;
  font-size: $fs--xxx-small;
  z-index: 1000;
  font-family: $ff--sans;
  @include border-radius($s1);
}

.tooltip.visible {
  display: block;
}

.link--external {
  font-size: $fs--x-normal;
  position: relative;
  padding-right: $s2;
  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 16px;
    bottom:1px;
    right: 0;
    display: block;
    background: transparent url('/resources/images/icon__arrow--link.svg') center center no-repeat;        
    @include multi-filter(brightness(0) grayscale(1));
    @include transition(all, 0.2s, ease-in-out);
  }
  &:hover {
    &::after {     
      @include multi-filter(brightness(1));
      @include transition(all, 0.2s, ease-in-out);
    }
  }  
  
}

.dark-mode {
  .link--external{
    &::after {      
      @include multi-filter(brightness(20) grayscale(1));
    }
    &:hover {
      &::after {     
        @include multi-filter(brightness(1));
      }
    }
  }
}
