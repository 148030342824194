// -- page home
.home {
  .works{
    margin-bottom: $s5;
    @include for-tablet {
      margin-bottom: $s10;
    }
    .pure-g{
      row-gap: 30px;
    }  
  }
}


.content-grid { 
  margin-bottom: $s6;
  @include for-tablet {
    margin-bottom: $s10;
  }
}

// -- page privacy
.privacy {
  .content-grid { 
    p {
      margin: $s2 0;
    }
  }
}


// -- page work
.work {
  .works{
    margin-bottom: $s5;
    @include for-tablet {
      margin-bottom: $s10;
    }
    .pure-g{
      row-gap: 30px;
    }  
  }
}


// -- page project
.project {  
  .pure-g{
    justify-content: space-between;
  }
  .project__intro {
    .page__title {
      margin: 0;
      padding: 0;
      font-size: $fs--xx-large;
      line-height: $lh--ultra;

    }
    .tags {
      gap: $s1-5;
      flex-wrap: wrap;
      row-gap: $s1-5;
      li {
        color: var(--text-100);
        font-size: $fs--xxx-small;
        line-height: $lh--xxx-small;
        text-align: left;
        opacity: 70%;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
    }
    @include for-desktop-hd {
      padding-right: $s6;

      .project__intro--content{
        position: sticky;
        top: $s10;
      }
    }
  }
  
  .project__content {
    .block {
      margin: $s2 0;
    }    
      
    p {
      margin: $s4 0 ;
    }
    blockquote {
      color: var(--text-100);
      font-size: $fs--xx-big;
      line-height: $lh--large;
      padding-top: $s5;
      // font-weight: $fw--regular;
      font-family: $ff--serif;
      // line-height: $lh--medium;

      footer {
        background-color: transparent;
        font-size: $fs--normal;
        margin-top: $s2;
        font-family: $ff--sans;
      }
    }
 
    figure {
      overflow: hidden;  
      width: 100%;
      //height: auto;       

      img {           
        width: 100%;
        height: 100%;  
        @include border-radius($s2);
      }
    }

    .block-type-2_images {
      .column__image {
        width: calc(50% - $s1);
      }
      .column__image:first-child {
        margin-right: $s1;
      }
      .column__image:last-child {
        margin-left: $s1;
      }
    }  

    .block-type-video {
      video {
        width: 100%;
        @include border-radius($s2);
      }
    }
 
    @include for-tablet {
      .column__content {
        display: flex;
        align-items: center;
        margin-left: $s6;
        margin-right: $s6;
        width: calc(50% - $s12);
        p {
          width: 100%;
        }
      }
    }
    @include for-desktop-hd {
      p, blockquote {
        width: 85%;
      }
    }
  }
  .related{
    margin-bottom: $s5;
    @include for-tablet {
      margin-bottom: $s10;
    }
  }
}


// -- page call
.call {
  .desktop {
    display: none;
  }
  @include for-tablet {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }  
}

// -- page contact
.contact {
  .form-sent-message {
    p {
      strong {
        color: var(--text-300);
        font-weight: $fw--light;
        line-height: $lh--big;
        margin: $s3 0;
        font-family: $ff--serif;
        @include fluid-type($fs--xx-medium, $fs--xxx-big); // 38px/44px - 28px/32px

        @include for-desktop {
          line-height: $lh--xx-large;    
        }
      }
    }
  }
  .content-grid {
    p {
      color: var(--text-100);
    }
  }
  form {
    margin-top: $s4;

    .small {
      color: var(--text-100);
    }
    .group-label {
      flex-direction: column;

      label {
        margin: $s3 0 $s1 0 ;
        flex-direction: row;
        position: relative;

        span {
          color: var(--text-300);
          margin: 0;
          padding: 0;
          font-family: $ff--serif;
        }      
        em {
          font-size: $fs--xxx-normal;
        }
      }
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid var(--text-300);
        color: var(--text-100);
        flex: 1 1 100%;
        font-size: $fs--xxx-small;
        margin: 0 0 0 0;
        padding: $s1 $s1-5 $s1 0;

        @include placeholder {
          color: var(--text-100);
        } 
      }
      &:last-child{
        flex-direction: column-reverse;
        span {
          text-align: center;
        }
      }
    }
    .button__cta {
      flex-direction: column-reverse;
      margin: $s4 auto $s2 auto;
    }

  

    @include for-tablet {        
      .group-label {
        position: relative;
        margin-right: $s2;
        flex-direction: row;       

        label {
          flex-direction: row;
          margin: 0 $s1 $s1 0 ;          
        }
        &::after {
          content: ' ';
          background-color: var(--text-300);
          bottom: 14px;
          right: -12px;
          position: absolute;
          width: 6px;
          height: 6px;

          @include border-radius($s2);          
        }
        
        input {
          flex: 1 1 auto;     
          margin: $s1 0 $s1-5 $s1-5; 
          padding: 0 $s1-5 $s1 $s1;     
        }
        .button__cta {
          margin: $s2;
        }
      } 
      .group-label:last-child {
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }     
      & > div:nth-last-of-type(1) div{
        &::after {
          display: none;
        }
      }  
    }
    @include for-desktop-hd {
      margin-top: 0;      

      .button__cta {
        flex-direction: row;
        margin: $s2;
      }
    }
  }
}

// -- section hero
.hero {
  h2 {
    padding-right: $s6;
  }
  .box__animation {
    max-width: 300px;
    margin: 0 auto;
  }   
  p{
    color: var(--text-100);
  }
  @include for-tablet {
    h2 {
      padding-right: $s10;
    }
    .box__animation {
      max-width: 450px;
    }
  }
  @include for-desktop-hd {
    h2 {
      padding-right: $s15;
    }
    .box__animation {
      max-width: 550px;
      margin: 0 0 0 auto;
    }
  }
}


// -- section works home
.related, .works {
  .card__work {
    margin-bottom: $s4;
  }
  @include for-mobile {    
    .pure-g {
      .pure-u-1:nth-child(odd) {
        .card__work{
          padding-right: $s2;
        }
      }
      .pure-u-1:nth-child(even) {
        .card__work{
          padding-left: $s2;
        }
      }
    }  
  }
}

// -- section information
.information{
  p {
    color: var(--text-100);
    margin: $s2 0;
  }
  .pure-g{
    justify-content: flex-start;
  }
}

// -- section services
.services {
  .card__service {
    margin-left: -$s2;
    margin-right: -$s2;
    padding: $s2;
    max-height: 24px;

    @include transition(all, 1.0s, ease-in-out);

    &.is-active {
      max-height: 700px;
      
      .card__title {
        background: transparent url('/resources/images/icon__minus.svg') right center no-repeat;
      }
    }

    .card__title {
      background: transparent url('/resources/images/icon__plus.svg') right center no-repeat;       
      
      &:hover {
        @include opacity(0.5);
      }
    }
  }
  @include for-tablet {    
    .card__service {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      max-height: 100%;

      @include transition(all, 0s, ease-in-out);
      
      &.is-active {
        max-height: 700px;
        
        .card__title {
          background: none;
        }
      }
      .card__title {
        background: none;
        padding-top: $s3;
      }
    }     
    .pure-g {
      .pure-u-1 {
        padding-bottom: $s3;
      }
      .pure-u-1:nth-child(odd) {
        .card__service{
          margin-right: $s1-5;
          
        }
      }
      .pure-u-1:nth-child(even) {
        .card__service{
          margin-left: $s1-5;
        }
      }
    }  
  }
  @include for-desktop-hd {    
    .pure-g {
      .pure-u-1:nth-child(odd) {
        .card__service{
          margin-left: $s1;
          margin-right: $s05;
        }
      }
      .pure-u-1:nth-child(even) {
        .card__service{
          margin-left: $s05;
          margin-right: $s1;
        }
      }
      .pure-u-1:first-child {
        .card__service{
          margin-left: 0;
          margin-right: $s1-5;
        }
      }
      
      .pure-u-1:last-child {
        .card__service{
          margin-left: $s1-5;
          margin-right: 0;
        }
      }
    }  
  }
}

// -- section process
.process{
  h2 {
    margin-top: 0;
  }
  .h4 {
    margin-bottom: $s1;
    display: inline-block;
  }
  p {
    color: var(--text-100);
    margin-bottom: $s2;
  }
  
  @include for-tablet {  
    .h4 {
      margin-bottom: $s2;
    }
    p {
      margin-bottom: $s4;
      margin-right: $s4;
    }
  }
}

// -- section clients
.clients {
  h2 {
    margin-top: 0;
  }
  ul {
    li {
      color: var(--text-100);
    }
  }
  @include for-tablet {  
    ul {
      margin: $s2 0;
    }
  }
}

