//
footer {
  background-color: var(--background-200); 
  width: 100%;
  bottom: 0;
  position: relative;
  z-index: 1;
  padding-bottom: 15%;

  #logo-footer {
    width: 100%;
    height: 200px;
    right: 0;
    bottom: -29.5%;  
    position: absolute;  

    .image-logo-footer {
      display: flex;
      transition: transform 0.3s linear;
      width: 100%;
      justify-content: center;

      img {
        width: 93%;        
      }
    }    

    .white-logo {display: none;}
    .black-logo {display: block;}
  }

  @include for-half-mobile {   
    #logo-footer {
      bottom: -30%;   
    }
  }

  @include for-mobile {   
    #logo-footer {
      bottom: -31%;   
    }
  }

  @include for-tablet { 
    padding-bottom: 50px;  
    #logo-footer {
      bottom: -160px;   
    }
  }

  @include for-desktop { 
    padding-bottom: 70px;  
    #logo-footer {
      bottom: -150px;   
    }
  }
  
  @include for-desktop-hd {   
    padding-bottom: 70px;
    #logo-footer {
      bottom: -170px;    

      .image-logo-footer {
        align-items: flex-end;
        justify-content: flex-end;

        img {
          width: 900px;        
        }
      }  
    }
  }

  .wrap {        
    flex-direction: column; 
    padding-bottom: $s2;
    position: relative;
    
    .block__intro{      
      text-align: center;   

      .box__contact {
        span {
          margin: 0;
        }     
        .button__cta {
          margin-left: auto;
          margin-right: auto;
        }   
      }
    }

    .block__nav{      
      text-align: center;   
      .subnav {
        li {
          margin: $s2;
          a {
            font-size: $fs--x-normal;
          }
        }
      }
    }

    .block__info{      
      text-align: center;   
      .link--email {
        margin: $s3 auto $s2 auto;
      }
      .box__animation {
        margin-left:auto;
        margin-right:auto;
        margin-bottom: $s4;
        width: 120px;
        canvas {
          width: 120px;
        }
      }
    }

    .block__end {   
      position: relative;   
      text-align: center;   
      z-index: 2;
      span {
        display: block;
      }
    }

    @include for-tablet {   
      .block__intro{
        order: 1
      }

      .block__nav{
        text-align: right;
        order: 3
      }

      .block__info{
        text-align: left;
        order: 2;
        display: flex;
        flex-direction: column;
        .box__txt {
          order: 2;
          margin-bottom: $s8;

          .link--email {            
            margin: $s2 0;
          }
        }
        .box__animation{
          order: 1;
          margin-left:0;
          margin-right:0;
          margin-bottom: $s2;
        }
      }

      .block__end{
        text-align: left;
        order: 4;

        span {
          display: inline-block;
        }
      }
    }
   
    @include for-desktop-hd {  
      .block__intro{
        order: 2;      
      }

      .block__nav{
        order: 3
      }

      .block__info{
        order: 1;   
        margin-bottom: $s2;    
      }
    }
  }  
  @include for-tablet {
    position: sticky;
    bottom: 0;
    left: 0;
  }
}

.dark-mode {
  footer {
    #logo-footer {
      .white-logo {display: block;}
      .black-logo {display: none;}
    }
    .wrap {
      .block__info{  
        .box__animation {
          canvas {
            @include multi-filter(brightness(4));
          }
        }
      }
    }
  }
}